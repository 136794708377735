var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","stripe":""},scopedSlots:_vm._u([{key:"loan_number",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan ? row.loan.loan_number : '')+" ")]}},{key:"customer",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan.customer ? row.loan.customer.customer_name_en : '')+" ")]}},{key:"loan_amount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.loan.loan_amount, row.loan.currency))+" ")]}},{key:"outstanding_amount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.outstanding_amount, row.loan.currency ))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }