<template>
    <div>
        <Table
            :columns="columns"
            :data="resources"
            size="small"
            stripe
        >
            <template slot-scope="{ row }" slot="loan_number">
                {{ row.loan ? row.loan.loan_number : '' }}
            </template>
            <template slot-scope="{ row }" slot="customer">
                {{
                    row.loan.customer ? row.loan.customer.customer_name_en : ''
                }}
            </template>
            <template slot-scope="{ row }" slot="loan_amount">
                {{ formatCurrencyWithCode(row.loan.loan_amount, row.loan.currency) }}
            </template>
            <template slot-scope="{ row }" slot="outstanding_amount">
                {{
                    formatCurrencyWithCode(
                        row.outstanding_amount,
                        row.loan.currency
                    )
                }}
            </template>
        </Table>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { orderBy } from 'lodash'
export default {
    props: {
        row: Object
    },
    computed: {
        ...mapGetters(['formatCurrencyWithCode']),
        resources () {
            return orderBy(this.row.details, ['cycle_payment_date'])
        },
        columns () {
            return [
                {
                    title: this.$t('loanTransfer.scheduleDate'),
                    key: 'cycle_payment_date',
                    sortable: true,
                    width: 200
                },
                {
                    title: this.$t('loanTransfer.loanNumber'),
                    key: 'loan_number',
                    slot: 'loan_number',
                    sortable: true,
                    width: 200
                },
                {
                    title: this.$t('loanTransfer.customerName'),
                    key: 'customer',
                    slot: 'customer',
                    sortable: true,
                    width: 350
                },
                {
                    title: this.$t('loanTransfer.loanAmount'),
                    key: 'loan_amount',
                    slot: 'loan_amount',
                    sortable: true,
                    width: 200,
                    align: 'right'
                },
                {
                    title: this.$t('loanTransfer.outstandingAmount'),
                    key: 'outstanding_amount',
                    slot: 'outstanding_amount',
                    sortable: true,
                    width: 200,
                    align: 'right'
                }
            ]
        }
    },
}
</script>
