<template>
    <div>
        <ts-page-title
            :title="$t('loanTransfer.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanTransfer.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t('addNew')
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('loanTransfer.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="from_co">
                    {{ row.from_co ? row.from_co.employee_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="to_co">
                    {{ row.to_co ? row.to_co.employee_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="createdBy">
                    {{ row.created_by ? row.created_by.employee.employee_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        :title="$t('are_you_sure_to_delete')"
                        :transfer="true"
                        width="240"
                        v-if="!row._deleting"
                        @on-popper-show="() => (model.deleted_reason = '')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                        <div slot="content">
                            <label class="required">{{
                                $t('journalEntry.reason')
                            }}</label>
                            <input
                                v-model.trim="model.deleted_reason"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        errors.has('deleted_reason') ||
                                        $v.model.deleted_reason.$error
                                }"
                            />
                            <span
                                class="invalid-feedback"
                                v-if="reasonErrors.length > 0"
                                >{{ reasonErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('deleted_reason')"
                            >
                                {{ errors.first('deleted_reason') }}
                            </div>
                            <div class="tw-mt-2 tw-text-right">
                                <a
                                    class="ivu-btn ivu-btn-primary ivu-btn-small"
                                    @click.prevent="onDelete(row)"
                                >
                                    <span>OK</span>
                                </a>
                            </div>
                        </div>
                    </Poptip>
                    <a
                        v-else
                        class="ml-2 text-danger"
                        v-tooltip="$t('delete')"
                        :disabled="row._deleting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-trash" size="20" v-else />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Drawer
                :title="$t('filter')"
                :closable="false"
                :value="visible"
                @on-close="() => (visible = false)"
                width="300px"
            >
                <div class="mb-3">
                    <label class="form-col-label control-label">{{
                        $t('branchName')
                    }}</label>
                    <ts-branch-filter @filter="value => (branch_id = value)" />
                </div>
            </Drawer>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('loanTransfer.pageTitle')"
                width="800"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import FromAction from './form'
import expandRow from './table-expand.vue'
import { required } from 'vuelidate/lib/validators'
import { Errors } from 'form-backend-validation'

export default {
    name: 'loanTransferIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false,
            visible: false,
            errors: new Errors(),
            branch_id: [],
            model: {
                deleted_reason: ''
            },
            width: 'width: 300px'
        }
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ['model.deleted_reason']
    },
    computed: {
        ...mapState('creditOperation/loanTransfer', [
            'resources',
            'pagination'
        ]),
        ...mapGetters(['formatCurrencyWithCode']),
        reasonErrors () {
            const errors = []
            if (!this.$v.model.deleted_reason.$error) return errors
            !this.$v.model.deleted_reason.required && errors.push('Required')
            return errors
        },
        search: {
            get () {
                return this.$store.state.creditOperation.loanTransfer.search
            },
            set (newValue) {
                this.$store.commit(
                    'creditOperation/loanTransfer/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'creditOperation/loanTransfer/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    type: 'expand',
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        })
                    },
                    width: 30
                },
                {
                    title: this.$t('loanTransfer.branch'),
                    slot: 'branch',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('loanTransfer.transferDate'),
                    key: 'transfer_date',
                    sortable: true,
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('loanTransfer.fromCo'),
                    slot: 'from_co',
                    sortable: true,
                    minWidth: 260
                },
                {
                    title: this.$t('loanTransfer.toCo'),
                    slot: 'to_co',
                    sortable: true,
                    minWidth: 260
                },
                {
                    title: this.$t('loanTransfer.reason'),
                    key: 'remarks',
                    minWidth: 250
                },
                {
                    title: this.$t('loanTransfer.createdBy'),
                    slot: 'createdBy',
                    minWidth: 150
                },
                {
                    title: this.$t('loanTransfer.createdDate'),
                    key: 'created_date',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('creditOperation/loanTransfer/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        onEdit (record) {
            this.$store.commit(
                'creditOperation/loanTransfer/SET_EDIT_DATA',
                record
            )
            this.showForm = true
            this.$refs.form_action.setEditData()
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit('creditOperation/loanTransfer/SET_EDIT_DATA', {})
        },
        addNew () {
            this.$store.commit('creditOperation/loanTransfer/SET_EDIT_DATA', {})
            this.showForm = true
            this.$refs.form_action.fetchResource()
            this.$refs.form_action.clearInput()
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LOAN TRANSFER',
                desc: not.text
            })
        },
        async onDelete (record) {
            this.$v.form.$touch()
            if (this.$v.form.$invalid) return

            record._deleting = true
            this.$store
                .dispatch('creditOperation/loanTransfer/destroy', {
                    id: record.transfer_co_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData()
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                    this.model.deleted_reason = ''
                })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search, branch_id: this.branch_id })
        }, 500),
        branch_id: debounce(function (value) {
            this.fetchData({ branch_id: value })
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/loanTransfer/RESET_STATE')
        next()
    },
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.fetchData()
    //     })
    // }
}
</script>
